import React, { useState } from "react";
import { Box, Button, Text, Input, Label } from "@adminjs/design-system";
const ChangePasswordPageComponent = (props) => {
    const [newPassword, setNewPassword] = useState("");
    const [resp, setResp] = useState("");
    const record = props.record;
    const handlePasswordChange = async () => {
        const response = await fetch("/api/hash", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ password: newPassword }),
        });
        const data = await response.text();
        setResp(data);
        const email = "self";
    };
    return (React.createElement(Box, { variant: "grey" },
        React.createElement(Box, { variant: "white", p: "20px", width: 1 / 2, mx: "auto", mt: "40px" },
            React.createElement(Text, { as: "h1" }, "\u0E23\u0E35\u0E40\u0E0B\u0E47\u0E15\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19"),
            React.createElement(Label, null, "\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E43\u0E2B\u0E21\u0E48"),
            React.createElement("p", null,
                "resp=",
                resp),
            React.createElement("p", null,
                "record=",
                JSON.stringify(record)),
            React.createElement(Input, { type: "password", placeholder: "\u0E01\u0E23\u0E2D\u0E01\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E43\u0E2B\u0E21\u0E48", value: newPassword, onChange: (e) => setNewPassword(e.target.value) }),
            React.createElement(Button, { mt: "20px", onClick: handlePasswordChange }, "\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19"))));
};
export default ChangePasswordPageComponent;
