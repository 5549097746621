import React, { useState } from "react";
const FileUploader = (props) => {
    const [file, setFile] = useState(null);
    const key = props.property.path.replace("Upload", "");
    const handleFileChange = (event) => {
        const reader = new FileReader();
        const file = event.target.files[0];
        setFile(file);
        reader.onloadend = () => {
            const result = reader.result;
            props.onChange(key, {
                name: file.name,
                file: result.split(",")[1],
            });
        };
        reader.readAsDataURL(file);
    };
    const rawName = props.record.params[key] || "";
    const temps = rawName.split(".") || [];
    const filenameFromRecord = temps.slice(1).join(".");
    const fileSize = file ? file.size / 1024 / 1024 : 0;
    const fileSizeText = fileSize.toFixed(2) + " MB";
    const where = props.where;
    const isEdit = where === "edit";
    const isShow = where === "show";
    const isList = where === "list";
    const hasFile = filenameFromRecord;
    const iconHasFile = () => {
        if (hasFile)
            return React.createElement("p", null, "\u2705");
        return React.createElement("p", null);
    };
    const delBtn = () => {
        return (React.createElement("button", { className: "pl-[1rem] ml-[1rem] p-3 hover:cursor-pointer", onClick: () => {
                setFile(null);
                props.onChange(key, "");
            } }, "\u274C"));
    };
    const fieldName = key.replace("file", "ไฟล์ ");
    return (React.createElement("div", null,
        isEdit && (React.createElement("div", { className: "pt-[0.5rem] pb-[4.5rem]" },
            React.createElement("div", { className: "row space-x-2" },
                React.createElement("div", { className: "tg py-1" }, fieldName)),
            !file && hasFile && (React.createElement("div", { className: "row py-2 pr-4" },
                React.createElement("p", null, filenameFromRecord),
                delBtn())),
            file && React.createElement("div", { className: "row py-2 pr-4" },
                React.createElement("p", null,
                    "size=",
                    fileSizeText),
                delBtn()),
            React.createElement("div", { className: "row" },
                React.createElement("input", { type: "file", onChange: handleFileChange })))),
        isShow && hasFile && (React.createElement("div", { className: "col" },
            React.createElement("p", { className: "tg py-1" }, fieldName),
            React.createElement("p", null, "\u2705"))),
        isList && iconHasFile()));
};
export default FileUploader;
