import React from "react";
import { useEffect } from "react";
const CreateNewUserComponent = (props) => {
    useEffect(() => {
        window.location.href = "/resources/User/actions/new";
    }, []);
    return (React.createElement("div", { className: "py-4" },
        React.createElement("p", { className: "text-gray-500" }, "Loading...")));
};
export default CreateNewUserComponent;
