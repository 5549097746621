import React from "react";
import { useTranslation } from "adminjs";
const ArrayComponent = (props) => {
    const { translateProperty } = useTranslation();
    const key1 = props.property.props["key1"];
    const key2 = props.property.props["key2"];
    const params = props.record.params;
    const data = Object.keys(params).filter((param) => param.includes(key1) && param.includes(key2));
    let text = "ว่าง";
    if (data.length > 0) {
        text = data.map((param) => params[param]).join(", ");
    }
    const label = props.property.label;
    const where = props.where;
    const isShow = where === "show";
    return (React.createElement("div", { className: "py-4" },
        isShow && (React.createElement("p", { className: "py-1 text-gray-500" }, translateProperty(label))),
        data.length < 0 && React.createElement("p", { className: "text-gray-700" }, "\u0E27\u0E48\u0E32\u0E07"),
        data && data.length > 0 && (React.createElement("ul", null, data.map((param, index) => (React.createElement("li", { key: param, className: "text-gray-700 space-y-1" },
            React.createElement("strong", null, index + 1),
            "\u00A0",
            params[param])))))));
};
export default ArrayComponent;
