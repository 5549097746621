import { useState } from "react";
import React from "react";
import { Box } from "@adminjs/design-system";
import { useEffect } from "react";
export default function Login(props) {
    const { action, errorMessage } = props;
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const url = window.location.href;
        if (url.includes("localhost")) {
            console.warn("skip login phase for localhost");
            const loginButton = document.getElementById("login_button");
            if (loginButton) {
                loginButton.click();
            }
            else {
                console.error("login button not found");
            }
            setLoading(false);
            return;
        }
        const isLogedIn = localStorage.getItem("isLogin") === "1";
        if (isLogedIn) {
            window.location.assign("https://www.pts.go.th/admin/dash/");
            localStorage.setItem("isLogin", "0");
        }
        else {
            setLoading(false);
        }
    });
    if (!loading) {
        return (React.createElement("div", { className: "flex justify-center items-center h-screen mx-10 mt-[-1rem]" },
            React.createElement(Box, { as: "form", action: action, method: "POST" },
                React.createElement("div", { className: "bg-white md:p-16 p-12 rounded-lg max-w-md w-[26rem] border shadow-lg shadow-pink-200" },
                    React.createElement("div", { className: "flex justify-center mb-8" },
                        React.createElement("div", { className: "w-[12rem]" },
                            React.createElement("img", { src: "../../public/logo.png", alt: "Logo" }))),
                    React.createElement("div", { className: "mb-4" },
                        React.createElement("label", { htmlFor: "username", className: "block text-sm font-medium text-gray-500 km" }, "\u0E0A\u0E37\u0E48\u0E2D\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49"),
                        React.createElement("input", { type: "text", id: "username", name: "email", value: username, onChange: (e) => setUsername(e.target.value), placeholder: "\u0E01\u0E23\u0E38\u0E13\u0E32\u0E01\u0E23\u0E2D\u0E01\u0E0A\u0E37\u0E48\u0E2D\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13", className: "km mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-pink-500 focus:border-pink-500 focus:outline-none sm:text-sm" })),
                    React.createElement("div", { className: "mb-6" },
                        React.createElement("label", { htmlFor: "password", className: "block text-sm font-medium text-gray-500 km" }, "\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19"),
                        React.createElement("input", { type: "password", id: "password", name: "password", value: password, onChange: (e) => setPassword(e.target.value), placeholder: "\u0E01\u0E23\u0E2D\u0E01\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13", className: "km mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-pink-500 focus:border-pink-500 focus:outline-none sm:text-sm" })),
                    React.createElement("button", { id: "login_button", className: "mt-4 w-full bg-pink-500 text-white font-bold py-2 px-4 rounded-lg hover:scale-105 focus:outline-none focus:ring-pink-500 focus:ring-opacity-50 transition-colors shadow-lg shadow-pink-300" }, "\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A")))));
    }
    return (React.createElement("div", { className: "h-full wf row cc" },
        React.createElement("div", { className: "flex items-center" },
            React.createElement("div", { className: "animate-spin rounded-full h-12 w-12 border-t-4 border-pink-500 border-solid" }),
            React.createElement("span", { className: "ml-4 text-lg tg kr" }, "\u0E01\u0E33\u0E25\u0E31\u0E07\u0E42\u0E2B\u0E25\u0E14..."))));
}
