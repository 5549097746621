export const PUBLIC_PATH = "public";
export const statusText = {
    w: "ผู้ป่วยรออยู่",
    c: "นัดหมายแล้ว",
    v: "มาพบแล้ว",
    x: "ยกเลิกนัด",
    m: "พลาดนัด",
};
export const dat = {
    img1: ["รูปภาพ 1", 320, 320],
    img2: ["รูปภาพ 2", 184, 184],
    img3: ["รูปภาพ 3", 128, 128],
    imgCover: ["รูปภาพหน้าปก", 600, 300],
    image: ["รูปภาพ", 128, 128],
    icon: ["ไอคอน", 64, 64],
};
export const statusSuggest = {
    a: "เสนอแนะ",
    k: "ทราบแล้ว",
    c: "แก้ไขแล้ว",
    d: "ลบแล้ว",
};
