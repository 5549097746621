import React from "react";
const NewsComponent = (props) => {
    const params = props.record.params;
    const news = Object.keys(params).filter((param) => param.includes("news") &&
        param.includes("title") &&
        !param.includes("subtitle"));
    const titles = news.map((param) => params[param]).join(", ");
    return (React.createElement("div", { className: "py-4" },
        React.createElement("p", { className: "text-gray-500" }, titles)));
};
export default NewsComponent;
