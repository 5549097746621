import React from "react";
import { statusText } from "../admin/constants.js";
const StatusComponent = (props) => {
    const status = props.record.params.status;
    const text = statusText[status];
    const where = props.where;
    const isShow = where === "show";
    return (React.createElement("div", { className: "row js ic" },
        React.createElement("div", { className: "hover:shadow-sm border-4 duration-300 whitespace-nowrap rounded-full" }, status === "w" ? (React.createElement("div", { className: `py-1 ${isShow ? "px-0 pb-[1.3rem]" : "px-4"} border-2 border-red-500 text-red-500 rounded-full` }, text)) : status === "v" ? (React.createElement("div", { className: `py-1 ${isShow ? "px-0 pb-[1.3rem]" : "px-4"} border-2 border-green-500 text-green-500 rounded-full` }, text)) : status === "c" ? (React.createElement("div", { className: `py-1 ${isShow ? "px-0 pb-[1.3rem]" : "px-4"} border-2 border-blue-500 text-blue-500 rounded-full` }, text)) : status === "x" ? (React.createElement("div", { className: `py-1 ${isShow ? "px-0 pb-[1.3rem]" : "px-4"} border-2 border-gray-500 text-gray-500 rounded-full` }, text)) : status === "m" ? (React.createElement("div", { className: `py-1 ${isShow ? "px-0 pb-[1.3rem]" : "px-4"} border-2 border-yellow-500 text-yellow-500 rounded-full` }, text)) : null)));
};
export default StatusComponent;
