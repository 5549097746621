AdminJS.UserComponents = {}
import ImageUploader from '../dist/components/ImageUploader'
AdminJS.UserComponents.ImageUploader = ImageUploader
import Image from '../dist/components/Image'
AdminJS.UserComponents.Image = Image
import ImageNoTitle from '../dist/components/ImageNoTitle'
AdminJS.UserComponents.ImageNoTitle = ImageNoTitle
import Status from '../dist/components/Status'
AdminJS.UserComponents.Status = Status
import FileUploader from '../dist/components/FileUploader'
AdminJS.UserComponents.FileUploader = FileUploader
import Props from '../dist/components/Props'
AdminJS.UserComponents.Props = Props
import News from '../dist/components/News'
AdminJS.UserComponents.News = News
import Array from '../dist/components/Array'
AdminJS.UserComponents.Array = Array
import Dashboard from '../dist/components/Dashboard'
AdminJS.UserComponents.Dashboard = Dashboard
import NewUser from '../dist/components/CreateNewUser'
AdminJS.UserComponents.NewUser = NewUser
import ChangePasswordPage from '../dist/components/ChangePasswordPage'
AdminJS.UserComponents.ChangePasswordPage = ChangePasswordPage
import Login from '../dist/components/login'
AdminJS.UserComponents.Login = Login