import React from "react";
import { dat } from "../admin/constants.js";
const ImageComponent = (props) => {
    const key = props.property.path.replace("Upload", "");
    const size = 50;
    return (React.createElement("div", { className: "py-4" },
        React.createElement("p", { className: "text-gray-500" }, dat[key][0]),
        React.createElement("img", { width: size, src: props.record.params[key], alt: "Preview", className: "img-thumbnail" })));
};
export default ImageComponent;
