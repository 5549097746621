import React, { useEffect } from "react";
const DashboardComponent = (props) => {
    useEffect(() => {
        localStorage.setItem("isLogin", "1");
    });
    const PUBLIC_MANUAL_URL = "https://doc.pts.go.th/docs/intro";
    const PUBLIC_FRONT_END = "https://www.pts.go.th";
    const PUBLIC_EDIT_PAGE_1 = "https://www.pts.go.th/admin/dash";
    const PUBLIC_EDIT_PAGE_2 = "https://backend.pts.go.th";
    return (React.createElement("div", { className: "row cc h-dvh" },
        React.createElement("div", { className: "py-4" },
            React.createElement("p", { className: "text-gray-700 text-4xl" }, "\u0E42\u0E2B\u0E21\u0E14\u0E41\u0E01\u0E49\u0E44\u0E02 2"),
            React.createElement("p", { className: "text-gray-500 pt-[1rem] text-xl" }, "\u0E42\u0E23\u0E07\u0E1E\u0E22\u0E32\u0E1A\u0E32\u0E25\u0E1E\u0E38\u0E17\u0E44\u0E18\u0E2A\u0E07"),
            React.createElement("div", { className: "col cc p-4 space-y-2" },
                React.createElement("a", { className: "hover:text-pink-400 duration-300 text-gray-500", href: `${PUBLIC_MANUAL_URL}`, target: "_blank", rel: "noopener noreferrer" }, "\uD83D\uDCD6 \u0E2D\u0E48\u0E32\u0E19\u0E04\u0E39\u0E48\u0E21\u0E37\u0E2D"),
                React.createElement("a", { className: "hover:text-pink-400 duration-300 text-gray-500", href: `${PUBLIC_FRONT_END}`, target: "_blank", rel: "noopener noreferrer" }, "\uD83C\uDFE0 \u0E2B\u0E19\u0E49\u0E32\u0E41\u0E23\u0E01"),
                React.createElement("a", { className: "hover:text-pink-400 duration-300 text-gray-500", href: `${PUBLIC_EDIT_PAGE_1}`, target: "_blank", rel: "noopener noreferrer" }, "\uD83D\uDEA7 \u0E42\u0E2B\u0E21\u0E14\u0E41\u0E01\u0E49\u0E44\u0E02 1"),
                React.createElement("a", { className: "hover:text-pink-400 duration-300 text-gray-500", href: `${PUBLIC_EDIT_PAGE_2}` }, "\uD83D\uDEA7 \u0E42\u0E2B\u0E21\u0E14\u0E41\u0E01\u0E49\u0E44\u0E02 2")))));
};
export default DashboardComponent;
