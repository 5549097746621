import React, { useState } from "react";
import { dat } from "../admin/constants.js";
const ImageUploadComponent = (props) => {
    const [file, setFile] = useState(null);
    const [alert, setAlert] = useState(null);
    const key = props.property.path.replace("Upload", "");
    const width = dat[key][1];
    const height = dat[key][2];
    const handleFileChange = (event) => {
        const reader = new FileReader();
        const file = event.target.files[0];
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
            if (img.width !== width || img.height !== height) {
                setAlert(`รูปภาพต้องมีขนาด ${width}x${height} พิกเซล`);
                return;
            }
            setFile(file);
            reader.onloadend = () => {
                const result = reader.result;
                props.onChange(key, {
                    name: file.name,
                    file: result.split(",")[1],
                });
            };
            reader.readAsDataURL(file);
        };
    };
    const src = props.record.params[key];
    return (React.createElement("div", { className: "py-4" },
        React.createElement("div", { className: "row js ic space-x-2" },
            React.createElement("p", { className: "pl-2 text-red-600" }, "*"),
            React.createElement("p", { className: "text-gray-600" },
                dat[key][0],
                `ขนาด ${width}x${height} พิกเซล`)),
        file ? (React.createElement("img", { src: URL.createObjectURL(file), alt: "Preview", width: 100 })) : (src && React.createElement("img", { width: 100, src: src, alt: "Preview" })),
        React.createElement("div", { className: "py-1" },
            React.createElement("input", { type: "file", onChange: handleFileChange }),
            alert && React.createElement("div", { className: "text-red-500" }, alert))));
};
export default ImageUploadComponent;
